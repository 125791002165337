import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getMe, userQuerySettings } from '../../../queries/user';
import { getAssignment, getOral, startOral, getAssignmentResults } from '../../../queries/student_assignments';
import Navigation from '../../../components/navigation/navigation';
import Button from '../../../components/button/button';
import Loading from '../../../components/loading/loading';
import { useRollbar } from '@rollbar/react';

import { determineCameraPermission } from './detect';

const DisplayReview = (props) => {
  const { review, index } = props;
  const items = [];
  review.qualitative.map((item) => {
    items.push(<p className="font-bold my-1" key={`label-${item.id}`}>{item.label}</p>);
    items.push(<p className="my-1" key={`answer-${item.id}`}>{item.answer}</p>);
  })

  let css = "my-4 p-1 border border-1 border-grey-400 bg-gray-100 p-4";
  let title = `#${index + 1} Review`;

  if (review.is_ai) {
    css = "my-4 p-1 border border-1 p-4 border-red-400 bg-red-200"
    title = `#${index + 1} AI Review`;
  }

  try {
    return (
      <div className={css}>
        <h4 className="text-lg font-bold">{title}</h4>
        {items}
        {!items.length && (<span>Your assignment had no textual review questions to display</span>)}
      </div>
    )

  } catch (e) {
    console.dir(e);
  }
}

const DisplayReviewResults = (props) => {
  const { reviews } = props;

  return (
    <div className="my-6">
      <h4 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
        Reviews of your Stage 1 Submission
      </h4>
      {reviews.map((object, i) => <DisplayReview review={object} index={i} key={`drr-${i}`} />)}
    </div>
  )
}

export const OralCreate = () => {
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const rollbar = useRollbar();
  const [hasPermission, setHasPermission] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['student-assignment', id], () => getAssignment(id));
  const oral = useQuery(['student-oral', id], () => getOral(id));
  const results = useQuery(['student-assignment-results', id], () => getAssignmentResults(id));


  const start = useMutation(startOral, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(['student-oral', id]);
    },
    onSuccess: (data, variables, context) => {
      history.push(`/oral/${id}/record`);
    },
    onError: (data, error, variables, context) => {
      rollbar.error(error, context);
    }
  })

  const handleClick = (event) => {
    event.preventDefault();
    start.mutate(id);
  }

  const handleGetPermission = async (event) => {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => setHasPermission(true))
      .catch((err) => {
        console.error(err);
        location.reload();
      });
  }

  if (assignment.isLoading || user.isLoading || oral.isLoading || results.isLoading) {
    return <Loading />;
  }

  navigator.permissions.query({ name: 'camera' }).then((result) => {
    if (result.state === "granted") {
      setHasPermission(true);
    }

    if (result.state === "denied") {
      setPermissionDenied(true);
    }
  }).catch((error) => {
    // Likely firefox which doesn't support permissions API the same way
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) => {
        setHasPermission(true);
      })
      .catch((error) => {
        setHasPermission(false);
      })
  })

  return (
    <div>
      <Navigation
        history={history}
        title={assignment.data.title}
        backURL={`/my-assignments/${id}`}
        hasBackButton
      />
      <div className="p-4">
        <div className="my-2">
          <h2 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
            Oral Assignment
          </h2>
        </div>
        <div className="my-4 p-1 border border-1 border-grey-400 bg-gray-100 p-4">
          <strong>Instructions:</strong>

          <p className="my-4">
            1. Below is the feedback you received on your submission. Please review this feedback.
          </p>

          <p className="my-4">
            2. When you are ready to submit your Oral Assignment, click the button below. This
            will start the process. Once you hit the <em>Begin Oral Assignment</em> button below:
          </p>
          <ul className="list-disc ml-6 my-4">
            <li>You will find questions to respond to.</li>
            <li>You will need a laptop or mobile device with a microphone and camera. Please speak clearly into the microphone.</li>
            <li>You will have 10 minutes to record your response to these questions.</li>
            <li>You instructor suggests that you recording be approximately 1 to 3 minutes.</li>
            <li>You will end your recording by hitting "Stop and Submit".</li>
            <li>After 10 minutes the timer will end and you will not be able to save your Oral Assignment.</li>
            <li>The default setting is video. You may cover your camera if you choose.</li>
            <li>You have "one take", meaning, you cannot delete and re-record your answers.</li>
            <li>Your instructor is interested in your response to the questions, not elements of public speaking.
              The recording will be better quality if you speak clearly, and not too quickly.</li>
          </ul>

        </div>

        {!hasPermission && !permissionDenied && (
          <div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
            You must grant permission to use your camera and microphone in your browser to record your oral assignment.
            You should see a popup message asking for your permission.
          </div>
        )}

        {!hasPermission && permissionDenied && (
          <div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
            <h4 className="font-bold text-2xl mb-4">Camera Permission Denied</h4>
            <p className="my-2">
              You have denied permission to use your camera and microphone.  This must be enabled to complete your Oral Assignment.
              You need to either enable these permissions manually or reset them so we can ask for your permission again.
            </p>

            <h5 className="font-bold text-xl my-4">For Chrome</h5>
            <ol className="list-decimal ml-8">
              <li>Click 'View Site Information' or the Lock icon to the left of the address bar</li>
              <li>Click 'Site settings</li>
              <li>Change Permission Settings or click 'Reset Permissions' button</li>
            </ol>
            <h5 className="font-bold text-xl my-4">For Firefox</h5>
            <ol className="list-decimal ml-8">
              <li>Open the menu and go to Preferences or Options</li>
              <li>Scroll down to Privacy & Security</li>
              <li>Go to Cookies & Site Data</li>
              <li>Select Clear Data</li>
            </ol>
            <h5 className="font-bold text-xl my-4">For Safari</h5>
            <ol className="list-decimal ml-8">
              <li>Click on the Safari app menu in the upper left corner of the screen</li>
              <li>Click on Settings for this website</li>
            </ol>
          </div>
        )}

        <div className="my-4">
          <h2 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
            Your Feedback
          </h2>
        </div>

        <DisplayReviewResults reviews={results.data.reviews} />

        {!permissionDenied && (
          <p className="mt-12 my-2 text-center">
            Please ensure you have read the instructions above before beginning your Oral Assignment.
          </p>
        )}

        {permissionDenied && (
          <p className="mt-12 my-2 text-center">
            Follow the instructions above in the red box to enable your camera and microphone.
          </p>
        )}

        <div className="my-4 text-center">
          {!oral.data.is_complete && hasPermission && (
            <Button
              onClick={handleClick}
            >Begin Oral Assignment</Button>
          )}
          {oral.data.is_complete && (
            <div className="w-full bg-green-100 border border-green-300 p-4">
              You have already completed your Oral Assignment.
            </div>
          )}

          {!hasPermission && !permissionDenied && (
            <Button
              onClick={handleGetPermission}
            >Enable Camera and Microphone</Button>
          )}
        </div>

      </div>
    </div>
  );
}


export default OralCreate;
